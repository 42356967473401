import * as Types from 'Shared/models/UserModel';
import { User } from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Shared/enums';

export type State = Types.UserStore;

export const initialState: State = {
  token: null,
  user: null,
  usersList: [],
  companies: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case User.GET_USERS_INIT: {
      return {
        ...state,
        // companiesOptions: {
        //   data: [],
        //   status: REQUEST_STATUS.INIT,
        // },
      };
    }
    case User.GET_USERS_SUCCESS: {
      return {
        ...state,
        // companiesOptions: {
        //   data: action.payload.companies,
        //   status: REQUEST_STATUS.SUCCESS,
        // },
      };
    }
    case User.GET_USERS_FAILURE: {
      return {
        ...state,
        // companiesOptions: {
        //   data: [],
        //   status: REQUEST_STATUS.FAILURE,
        // },
      };
    }
    default: {
      return state;
    }
  }
}
