export enum Auth {
  LOG_IN_INIT = 'LOG_IN_INIT',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  LOG_OUT_INIT = 'LOG_OUT_INIT',
  LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS',
  LOG_OUT_FAILURE = 'LOG_OUT_FAILURE',
  GET_AUTHORIZED_USER_INIT = 'GET_AUTHORIZED_USER_INIT',
  GET_AUTHORIZED_USER_SUCCESS = 'GET_AUTHORIZED_USER_SUCCESS',
  GET_AUTHORIZED_USER_FAILURE = 'GET_AUTHORIZED_USER_FAILURE',
  SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN',
  SEND_LINK_INIT = 'SEND_LINK_INIT',
  SEND_LINK_SUCCESS = 'SEND_LINK_SUCCESS',
  SEND_LINK_FAILURE = 'SEND_LINK_FAILURE',
  GET_REFRESH_TOKEN_INIT = 'GET_REFRESH_TOKEN_INIT',
  GET_REFRESH_TOKEN_SUCCESS = 'GET_REFRESH_TOKEN_SUCCESS',
  GET_REFRESH_TOKEN_FAILURE = 'GET_REFRESH_TOKEN_FAILURE',
  ADD_NEW_DEVICE_INIT = 'ADD_NEW_DEVICE_INIT',
  ADD_NEW_DEVICE_SUCCESS = 'ADD_NEW_DEVICE_SUCCESS',
  ADD_NEW_DEVICE_FAILURE = 'ADD_NEW_DEVICE_FAILURE',
}

export enum ViewManagement {
  SHOW_LOADER = 'SHOW_LOADER',
  HIDE_LOADER = 'HIDE_LOADER',
  SET_PREV_PATH = 'SET_PREV_PATH',
  EXPAND_MENU = 'EXPAND_MENU',
  EXPAND_ADD_REMINDER_VIEW = 'EXPAND_ADD_REMINDER_VIEW',
  OPEN_COMPENDIUM_PREVIEW_MOBILE = 'OPEN_COMPENDIUM_PREVIEW_MOBILE',
  CLOSE_COMPENDIUM_PREVIEW_MOBILE = 'CLOSE_COMPENDIUM_PREVIEW_MOBILE',
}

export enum User {
  GET_USERS_INIT = 'GET_USERS_INIT',
  GET_USERS_SUCCESS = 'GET_USERS_SUCCESS',
  GET_USERS_FAILURE = 'GET_USERS_FAILURE',
}

export enum Order {
  CREATE_ORDER_INIT = 'CREATE_ORDER_INIT',
  CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE = 'CREATE_ORDER_FAILURE',
}
