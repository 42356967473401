import { ViewManagementStore } from 'Shared/models/ViewManagementModel';
import { Order } from '../Actions/EnumTypes/ActionEnumTypes';
// import { ViewsManagemenetAction } from '../Actions/Models/ViewManagementActionModel';

export type State = ViewManagementStore;

export const initialState: State = {
  loaderVisible: false,
};

export function orderReducer(state: State = initialState, action) {
  switch (action.type) {
    case Order.CREATE_ORDER_INIT: {
      return {
        ...state,
      };
    }
    case Order.CREATE_ORDER_SUCCESS: {
      return {
        ...state,
        loaderVisible: true,
      };
    }
    case Order.CREATE_ORDER_FAILURE: {
      return {
        ...state,
      };
    }

    default: {
      return state;
    }
  }
}
