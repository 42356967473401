import React from 'react';
import ReactDOM from 'react-dom/client';
import 'Assets/Sass/style.scss';
import { RouterProvider } from 'react-router';
import { router } from 'Shared/utils';
import { Provider } from 'react-redux';
import { store } from 'Store/Store';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);
