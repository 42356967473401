enum SVG_TYPE {
  ARROW_RIGHT = 'ARROW_RIGHT',
  CIRCLE = 'CIRCLE',
  CHECKED = 'CHECKED',
  SEND_FILE = 'SEND_FILE',
  BACK_CHEVRON = 'BACK_CHEVRON',
  TIES = 'TIES',
  CLOSE = 'CLOSE',
  SEARCH = 'SEARCH',
  PERSON = 'PERSON',
  AWARD = 'AWARD',
  USER_CHECK = 'USER_CHECK',
  RECOMMENDATION = 'RECOMMENDATION',

  //flags
  FLAG_PL = 'FLAG_PL',
  FLAG_EN = 'FLAG_EN',
  FLAG_DE = 'FLAG_DE',
  FLAG_FR = 'FLAG_FR',
  FLAG_UA = 'FLAG_UA',
  FLAG_ES = 'FLAG_ES',
  FLAG_IT = 'FLAG_IT',

  //specializations
  ARCHITECTURE = 'ARCHITECTURE',
  ECONOMIC = 'ECONOMIC',
  IT = 'IT',
  MARKETING = 'MARKETING',
  MED = 'MED',
  LAW = 'LAW',
  TECHNICAL = 'TECHNICAL',
  
  //files
  PDF = 'PDF',
  DOC = 'DOC',
  DOCX = 'DOCX',
  JPG = 'JPG',
  PNG = 'PNG',
  TXT = 'TXT',
  CSV = 'CSV',
  XLS = 'XLS',
}



export default SVG_TYPE;
