import {
    createBrowserRouter,
  } from "react-router-dom";
import HomePage from "Screens/HomePage/Container";
import { ROUTES } from "Shared/enums";
  
  
export const router = createBrowserRouter([
  {
    path: ROUTES.HOME_PAGE,
    element: <HomePage />,
  },

]);