import { MainType } from './Container';

function HomePage(props: MainType) {
  const { } = props;
  return (
    <div className="home-page">
      Strona RoyalAcademy
    </div>
  );
}

export default HomePage;
